@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

$primarycolor1:rgb(12,76,163);
$primarycolor2:rgb(77,183,72);

$colorHover1:rgb(101, 160, 243);
$colorHover2:rgb(133, 223, 128);

$colorBoxShadow:rgba(0, 0, 0, 0.35) 0px 5px 15px;
$colorBoxShadow1:$primarycolor1 0px 5px 15px;
$colorBoxShadow2:$primarycolor2 0px 5px 15px;



*{
    box-sizing: border-box;
    font-size: 62.5%;

}
body{
    font-size: 16px;
}

li,ul{
    padding: 0;
    margin: 0;
    list-style:none;
}
a{
    text-decoration: none;
}
p{
    margin :0;
}
input{
    margin: 0;
}
.m-r-4{
    margin-right: 4px;
}
.d-f-s{
    display: flex;
    justify-content: space-between;
}
.d-f-c{
    display: flex;
    justify-content: center;
}
.t_a-c{
    text-align: center;
}


.none{
    display: none;
}

.box-shadow{
    box-shadow: 0 0 0 3px rgb(14 112 255 / 20%);
}
.swal2-popup {
    font-size: 1.6rem !important;
    font-family:monospace;
    color:$primarycolor1;

}
.swal2-styled.swal2-confirm{
    font-size: 1.6rem !important;
}
.swal2-success-line-tip{
    top: 2.875rem !important;
    left: 0.8125rem !important;
    width: 1.5625rem !important;
}
.swal2-success-line-long{
    top: 2.375rem !important;
    right: 0.5rem !important;
    width: 2.9375rem !important;
}
.swal2-icon.swal2-success .swal2-success-ring{
    top: -0.25rem !important;
    left: -0.25rem !important;
    border: 0.25rem solid !important
}
.swal2-icon.swal2-success .swal2-success-fix{
    top: 0.5rem !important;
    left: 1.625rem !important;
    width: 0.4375rem !important;
    height: 5.625rem !important;
}
.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=left]{
    top: -0.4375rem !important;
    left: -2.0635rem !important;
}
.swal2-icon.swal2-error [class^=swal2-x-mark-line]{
    top: 2.3125rem !important;
    width: 3.9375rem !important;
    height: 0.3125rem !important;
}
.swal2-html-container{
    color:$primarycolor2;
    font-weight: 600;
    font-size: 2.5rem;
}

