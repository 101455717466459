@import "../../_base";

.ListScreen{

    &__title{
        font-size: 3rem;
        color: $primarycolor1;
        text-transform: uppercase;
        font-weight: 600;
        text-align: center;
        margin: 40px 0 20px 0;
    }

    &__screen{
        
        ul{
            border: 1px solid #ccc;
            display: grid;
            grid-template-columns: repeat(3,1fr);
            gap: 15px;
            padding: 20px;
            box-shadow: $colorBoxShadow1;

            li{
                font-size: 2.5rem;
                padding: 20px 15px;
                display: flex;
                justify-content: center;
                box-shadow: $colorBoxShadow;
                cursor: pointer;
                align-items: center;
                transition: .3s all ease;
                border-radius: 5px;

                &:hover{

                    color: #ccc;
                    background: $primarycolor1;
                }
            }
        }

    }
}