.ServiceLayout{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    column-gap: 30px;
    row-gap: 10px;
    padding:0 40px;
    margin-top: 70px;
}
.ServiceLayout-item{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    font-size: 2.5rem;
    cursor: pointer;
    transition: .5s all ease;
    color: #0075c7;
    font-weight: 600;
    &:hover{
        background: #48abf2;
        color: white;
        box-shadow: #0075c7 0px 5px 15px;
    }
}
.btn-getnumber{
    grid-column-start:2 ;
    margin-top: 50px;
}