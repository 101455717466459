@import "../../_base";
.formpay{

    .button_pay{
        position: fixed;
        bottom: 5%;
        right: 40px;
    }
    padding: 20px;
    margin-top: 10px;
    border: 1px solid #ccc;
    &__top{

        &-group{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 15px 20px;
            div{
                display: flex;

                p{
                    font-size: 1.8rem;
                    margin-right: 20px;

                    &:first-child{
                        white-space: nowrap;
                    }
                    &:nth-child(2){
                        width: unset;
                        color: $primarycolor1;
                        font-weight: 600;
                    }
                }
                input{
                    font-size: 1.5rem;
                    padding: 0 10px;
                    height: 30px;
                    width: 100%;
                }
                .total-pay{
                    color: $primarycolor1;
                    font-weight: 600;
                }
            }
        }

       
    }

    &__table{
        margin-top: 20px;
    }

    .formpay__bottom{
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        

        p {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.8rem;
            border: 1px solid $primarycolor1;
            box-shadow: $primarycolor1 0px 4px 12px;
            padding: 10px 20px;
            border-radius: 5px;
            cursor: pointer;
            transition: .3s all ease;
            font-weight: 600;
            &:hover{
                background: $primarycolor2;
                color: #fff;
                border: none;
            }
        }
    }
}
.App__header{
    display: none;
}