@import "../../_base";

.backbutton {
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    width: max-content ;
    border: 1px solid $primarycolor1;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s all ease;
    font-weight: 600;
    box-shadow: $primarycolor1 0px 4px 12px;
    margin-top: 30px;
    // position: fixed;
    bottom: 60px;
    svg {
      font-size: 1.8rem;
    }

    &:hover {
      background: $primarycolor2;
      color: #fff;
      border: none;
    }
  }
}
