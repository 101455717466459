@import "../../_base";

.login-container {
  position: fixed;
  background: linear-gradient(90deg, $primarycolor2 0%, $primarycolor1 100%);
  width: 100%;
  height: 100vh;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .loginForm {
    height: max-content;
    padding: 10px 20px;
    background: #fff;
    width: 375px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    &-title {
      display: flex;
      justify-content: center;
      h3 {
        font-size: 2rem;
      }
    }
    &-groupby {
      margin-bottom: 20px;
      p{
        margin-left: 40px;
        font-size: 1.6rem;
        color: #ccc;
        margin-top: 15px;
      }
      input {
        width: 100%;
        height: 35px;
        font-size: 1.6rem;
        border-radius: 3px;
        border: none;
        border-bottom: 3px solid #d9d9d9 ;
        padding: 5px 10px;
        &:hover{
            box-shadow: none;
            border-bottom: 3px solid rgba(97, 188, 237, 0.35)  ;

        }

        &:focus{
            box-shadow: none;
            border-bottom: 3px solid rgba(97, 188, 237, 0.35)  ;
          outline: none;
        }
      }
    }
  }
  .btn-login{
    width: 80%;
     background: linear-gradient(90deg, $primarycolor2 0%, $primarycolor1 100%);
     color: #fff;
     font-size: 1.7rem  ;
    margin:40px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:10px  20px;
    border-radius: 50px;
    cursor: pointer;
    transition: .5s all ease;

    &:hover{
        background: linear-gradient(90deg, $primarycolor1 0%, $primarycolor2 100%);
    }
  }
}

