@import "../../_base";

.screen{

    td{
        text-align: center !important;
    }
    margin-top: 30px;

    &__title{
        text-align: center;
        margin-bottom: 20px;

        h2{
            color:$primarycolor1;
            font-size: 2.5rem;
            margin: 0;
            font-weight: 600;
            text-transform: uppercase;
        }
        p{
            color: blue;
            font-size: 2rem;
        }
    }

    &__table{
        display: flex;
     
        table{
            border :1px solid #ccc;
            border-collapse: collapse;
            width: 50%;
            border-color: #0094fe;
    
            th{
                background: #0075c7;
                color: white;
                padding: 10px 0;
                font-size: 2.2rem;

            }

            td{
                height:46px;
                color: #0075c7;
                font-size: 2rem;
                padding: 0 20px;
                font-weight: 600;
                text-align: center !important;
            
                
            }
           
    
        }
        
    }

}
table:nth-child(2){
    border-left: none;
}