@import "../../_base";


.HomePage{
    margin-top: 40px;
    .header__title-flow{

        width: 100%;
         margin:10px auto 30px auto;
        p{
            font-weight: bold;
            color: $primarycolor1;
            font-size: 2.5rem !important;
            animation-name: rightToLeft;
            animation-duration: 10s;
            animation-timing-function: linear;
            font-weight: 600;
            animation-iteration-count: infinite;
            text-transform: uppercase;
            white-space: nowrap;

        }
    }

    p {
        font-size: 2rem;
    }
    &__list{
       display: grid;
       grid-template-columns: repeat(2,1fr);
       padding: 0 0 20px 0;
        width: 100%;
        column-gap: 20px;
        row-gap: 40px;
        
       
    }
    &__item{
        display: flex;
        align-items: center;
        border: 1px solid #333;
        cursor: pointer;
        transition: .2s all ease;
        border-radius: 50px;
        height: max-content;
        padding: 20px 20px 20px 60px;
        // background: $primarycolor1;
        border: 1px solid $primarycolor1;
        // box-shadow: $primarycolor1 0px 5px 5px;
        box-shadow: $primarycolor1 0px 4px 12px;

        &:hover{
            border: none;
            background: $primarycolor1;
            color: white;
        }

        p{
            font-size: 3rem;
        }

        svg{
            font-size: 4rem;
            margin-right: 20px;
            color: $primarycolor2;

        }
    }
    @keyframes rightToLeft {
        0% {
          transform: translateX(100%);
        }
        100% {
          transform: translateX(-90%);
        }
      }
}