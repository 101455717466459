.ScreenCC {
  table {
    tbody {
      tr:nth-child(even) {
        background-color: rgb(133, 223, 128) !important;
        color: #fff !important;
      }

      td:last-child{
        text-align: center;
      }
    }
  }
}
