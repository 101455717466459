@import "../../_base";

.map-container{
    
    text-align: center;

    h2{
        color:$primarycolor1;
        margin: 20px 0 10px 0;
        font-size: 3rem;
        text-transform: uppercase;
    }

    img{
        width: 70%;
        height: 500px;
    }
}