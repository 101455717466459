@import "../../_base";

.rating__page{
    margin-top: 150px;
    text-align: center;

    p{
        font-size: 3.5rem;
        color: $primarycolor1;
        margin-bottom: 30px;
    }
    .rating-active{
        color: yellow;
    }
    svg{
        font-size: 4rem;
        margin-right: 10px;
        font-weight: 400;
        cursor: pointer;
        
    }

    
}