@import "../../_base";

.getNumber {
  &__title {
    width: 100%;
    text-align: center;
    font-size: 2.5rem;
    color: $primarycolor1;
    margin: 40px 0;
  }

  &__container {
    display: flex;
    img {   
      width: 400px;
      height: 450px;
    }

    .getNumber__content {
      flex: 1;
      justify-content: center;
      display: flex;
      flex-direction: column;

      .checkbox-group {
        padding: 0 10px;
        margin-bottom: 5px;
        cursor: pointer;

        input {
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-size: contain;
          margin-right: 10px;
        }

        label {
          font-size: 2rem;
          color: #0075c7;
        }

        &:nth-child(2) {
          margin-right: 4px;
        }
      }

      ul {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding: 0 20px;
        column-gap: 20px;
        margin-bottom: 20px;
        li {
          font-size: 2.3rem;
          color: $primarycolor1;
          padding: 20px 20px;
          border: 1px solid #ccc;
          box-shadow: $colorBoxShadow;
          text-align: center;
          line-height: 100%;
          font-weight: 600;
          cursor: pointer;
          transition: 0.3s all ease;
          border-radius: 5px;

          &:hover {
            background: $primarycolor1;
            color: #fff;
          }
        }
      }
      .getNumber__item-active {
        background: $primarycolor1;
        color: #fff;
      }

      .submit__number {
        text-align: center;
      }
      p {
        color: $primarycolor2;
        font-size: 2rem;
        margin: 10px 0 5px 0;
      }

      .btn__getnumber {
        color: white;
        background: #0075c7;
        padding: 20px;
        width: max-content;
        height: 30px;
        border-radius: 5px;
        cursor: pointer;
        font-size: 1.8rem;
        margin: auto;
        display: flex;
        align-items: center;
        font-weight: 600;
      }
    }
  }
  .backbutton {
    div{
      right: 60px !important;
      bottom: 50px;
      position: fixed;
    }

  }
}
.checkbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
