@import "../../_base";



.App-container{
    padding: 0 4rem;
}
.getNumber__clinical{
    .getNumber{

        &__title{
            width: 100%;
            text-align: center;
            font-size: 2.5rem;
            color: $primarycolor1;
            margin-bottom: 20px ;
            margin-top: 0;
        }
    
        &__container{
            display: flex;
            
            img{
                width:400px ;
                height: 450px;
            }
    
            .getNumber__content{
                flex: 1;
                justify-content: flex-start;
                display: flex;
                flex-direction: row;
                position: relative;
    
                .checkbox-group{
                    padding: 0 10px;
                    margin-bottom: 5px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
    
                    input{
                        width: 20px;
                        height: 20px;
                        background-repeat: no-repeat;
                        background-size: contain;
                        margin-right: 10px;
                    }
        
                    label{
                        font-size: 2.5rem;
                        color: #0075c7;
                        white-space: nowrap;
                    }
    
                   
                }
    
                .submit__number{
                    text-align: center;
                }
                p{
                    color: red;
                    font-size :2rem;
                    margin: 10px 0 5px 0;
                }
    
                .btn__getnumber{
                    color: white;
                    background: #0075c7;
                    padding: 20px;
                    width: max-content;
                    height: 30px;
                    border-radius: 5px;
                    cursor: pointer;
                    font-size: 2rem;
                    margin: auto;
                    display: flex;
                    align-items: center;
                    font-weight: 600;
                }
            }
        }
    }
    .checkbox{
        display: block !important;
    }
    .dropdown-area{
        position: relative;
        width: 300px;
    
        .dropdown-area-input{
            width: 100%;
            border: 2px solid $primarycolor1;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border-radius: 3px ;
            padding:10px 20px 10px 20px;
            font-size: 1.9rem;
            position: relative;
            transition: .3s all ease;
            
            &:hover{
                border-color: #0075c7;
            }
    
            svg {
                position: absolute;
                right: 10px;
                top: 13px;
                font-size: 1.5rem;
            }
        }
        ul {
            width: 100%;
            background: #fff;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            max-height: 0;
            visibility: hidden;
            opacity: 1;
            transition: .3s all ease;
            overflow: hidden;
    
    
                li{
                padding: 10px 20px;
                cursor: pointer;
                display: flex;
                justify-content: flex-start;
                font-size: 1.8rem;
                white-space: nowrap;
                
                &:hover{
                    background: #9acef3;
                }
            }
        }
    }
    .dropdown_arae-active{
        visibility: visible !important;
        opacity: 1 !important;
        max-height: 500px !important;
    }
    .getNumber__inputID{
        width: 200px;
        margin: auto;
        bottom: 30%;
        right: 20%;
        position: fixed;
        input {
            width: 100%;
            height: 30px;
            
            &:focus{
                outline: none;
            }
        }
        label {
            font-size: 1.5rem;
        }
    }
}