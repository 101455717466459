@import "./_base";

.App{
    overflow-x: hidden;
    height: auto;
    &__header{
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        padding: 0 4rem;
        img{
            max-width: 350px;
        }
        div{
            font-size: 2.5rem;
            color: $primarycolor1;
            font-weight: 600;
        }
    }
    ::-webkit-scrollbar {
        width: 8px;
        height:8px;
    }
    ::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 10px;
    }
    &__container{
        padding: 0 4rem 1rem 4rem;
    }
    input{
        border: 1px solid #ccc;

        &:hover{
            border-color: #5da5f7;
            outline: none;
            box-shadow: 0 0 0 2px rgb(94 141 209 / 20%);
        }
        &:focus{
            border-color: #5da5f7;
            outline: none;
            box-shadow: 0 0 0 2px rgb(94 141 209 / 20%);
        }
    }
}